<template>
  <div style="height: 100%">
    <div style="display: flex;background-color: #FFFFFF;
    border: 1px solid #dee2e6;justify-content: space-between;border-radius: 3px;align-items: center">
      <Breadcrumb style="border: none" :model="items"/>
      <Button style="height: 33px" class="p-button-text p-mr-2" @click="$router.go(-1)">返回</Button>
    </div>
    <div class="page p-mt-3 p-p-5">
      <div class="p-col-10">

        <div class="p-pt-2" style="font-size: 20px;font-weight: 600;text-align: center;width: 100%">{{ data.title }}
        </div>

        <div style="width: 100%;text-align: center">时间：{{ data.time }}</div>

        <div class="p-pt-4 p-ml-3 " v-html="data.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageDetail",
  data() {
    return {
      items: [
        {label: '消息管理', to: '/message'},
        {label: '消息详情',}
      ],
      data: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let id = this.$route.query.id
      this.$http.get('/send/getOneMessage?id=' + id).then((res) => {
        console.log(res.data)
        res.data.time = new Date(res.data.time).toLocaleString();
        this.data = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>